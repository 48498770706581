@media(max-width: 1250px){
    .container{
        max-width: 996px;
    }
    .slider-container{
        margin-left: calc(((100vw - 996px)/2) + 48px);
    }
    .main-slider{
        height: 300px;
    }
    .main-slider .main-slider-item img{
        height: 300px;
    }
    .footer-menu-wrapper{
        grid-template-columns: 1.2fr .9fr .9fr;
    }
    h2{
        font-size: 26px;
    }
    .profile-card{
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
    }
}

@media(max-width: 996px){
    .categories-list{
        display: flex;
    }
    h2{
        font-size: 24px;
    }
    .container{
        max-width: 100%;
        padding: 0 24px;
    }
    .footer-menu-wrapper{
        grid-template-columns: 1fr 1fr;
    }
    .main-slider{
        height: 250px;
    }
    .main-slider .main-slider-item img{
        height: 250px;
    }
    .stories-slider .slick-track{
        gap: 10px;
    }
    .stories-item img{
        height: 192px;
        border-radius: 24px;
        border: 2px solid var(--accentColor);
    }
    .stories-item.visited{
        border: none;
        img{
            border: 2px solid #DDD;
        }
    }
    .stories-item{
        padding: 0;
        border: none;
    }
    .slider-container{
        margin-left: 0;
    }
    .grid{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .menu-burger{
        display: flex;
    }
    .top-bar{
        padding: 8px 0;
        border: none;
    }
    .mobile-menu{
        display: flex;
        width: 100%;
        flex-direction: column;
    
        .flex{
            width: 100%;
            align-items: center;
        }

        .top-bar-wrapper{
            margin-top: 16px;
            .btn-outline{
                margin-left: auto;
            }
        }
    }
    .top-navigation{
        border-bottom: 1px solid #DDD;
        .lang-droupdown{
            padding: 8px 0;
        }
        /*position: fixed;
        background: #fff;
        transform: translateX(-120%);
        width: 350px;
        height: 100vh;
        z-index: 11;
        transition: .3s;

        .close-button{
            display: flex;
            justify-content: end;
            font-size: 24px;
        }

        &.open{
            transform: translateX(0);
        }

        .mob-user{
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 16px;
            margin-top: 16px;
        }
        .flex{
            flex-direction: column;
        }
        .top-nav{
            flex-direction: column;
            padding: 0;
            gap: 16px;

            a{
                color: #222831;
            }
        }
        .lang-droupdown{
            display: flex;
            justify-content: center;
        }*/
    }
    .menu{
        padding: 0;
        .nav{
            display: none;
        }
        .main-btn{
            position: fixed;
            z-index: 10;
            bottom: 20px;
            right: 20px;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            .vis-none{
                display: none;
            }
            i{
                margin: 0;
            }
            .count-icon{
                position: absolute;
                top: 0;
                right: 0;
                background: var(--accentColor);
                width: 18px;
                height: 18px;
                border-radius: 50%;
                font-size: 10px;
            }
        }
    }
    .menu{
        border: none;
    }
    .menu.sticky{
        padding: 0;
    }
    /*.product-modal-wrapper{
        grid-template-columns: 400px 1fr;
    }*/
    .product-modal .modal-dialog{
        max-width: 100%;
        padding: 0 16px;

        .close{
            top: -40px;
            right: 0;
        }
    }
    .checkout, .profile{
        margin: 30px 0;
    }
    h6{
        font-size: 16px;
    }
    /*.tab-links{
        display: flex;
        gap: 8px;
        overflow-x: auto;
        margin-bottom: 10px;
    }
    .tab-links .tab-links-item a{
        padding: 11px 22px;
        border-radius: 30px;
        white-space: nowrap;
    }*/
    .address-modal{
        .close{
            top: -40px;
            right: 0;
        }
        .modal-dialog{
            max-width: 767px;
            padding: 0 16px;
        }
    }
    .top-button{
        bottom: 78px;
        width: 40px;
        right: 24px;
        height: 40px;
        font-size: 22px;
    }
}
.vis-none{
    display: flex;
}
@media(max-width: 767px){
    .product-modal .modal-dialog .close{
        top: 12px;
        right: 12px;
        background: #fff;
        border-radius: 50%;
        color: #000000;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
    }
    .close{
        right: 0;
        top: -40px;
    }
    /*.profile-dashboard{
        padding: 16px;
    }*/
    .order-price{
        font-size: 16px;
    }
    .address-grid{
        grid-template-columns: 1fr;
    }
    .profile-slider .slick-track .slick-slide{
        margin-right: 10px;
    }
    .footer-menu-wrapper{
        grid-template-columns: 1fr;
    }
    .main-slider{
        height: calc(100vw/2.7);
    }
    .main-slider .main-slider-item img{
        height: calc(100vw/2.7);
    }
    .main-slider .main-slider-item{
        padding: 0 8px;
    }
    .login-modal-container{
        overflow: hidden;
        .modal-dialog{
            max-width: var(--bs-modal-width);
        }
        .close{
            top: -40px;
            right: 0;
        }
    }
    .grid{
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    .top-bar{
        .top-bar-wrapper{
            .input-wrapper, .input{
                width: 100%;
            }
            .top-text, .main-btn{
                display: none;
            }
        }
        .mobile-menu .flex{
            display: grid;
            grid-template-columns: 1fr 1fr;
            .top-bar-address{
                margin: 18px 0 0;
                font-size: 20px;
                grid-column: 1/3;

                .location p{
                    text-decoration: none;
                }
                .delivery-top{
                    display: none;
                }
            }
        }
    }
    .top-navigation{
        display: none;
    }
    .bottom-bar-wrapper{
        flex-direction: column;
        padding: 16px 0;
        align-items: center;
        gap: 10px;
    }
    .recent-slider .slick-slide{
        padding: 10px 0;
    }
    .product-modal-wrapper{
        //grid-template-columns: 1fr;
        display: block;
        padding: 8px;
        img{
            height: 340px;
            min-height: auto;
            border-radius: 16px;
        }
        /*.product-heart{
            right: auto;
            left: 0;
            top: 0;
        }*/
    }
    .product-modal-wrapper h2{
        font-size: 20px;
    }
    .delivery-items{
        flex-direction: column;
    }
    .box{
        padding: 16px;
    }
    .address-modal{
        .modal-dialog{
            max-width: 100%;
            padding: 0 16px;
        }
    }
    .menu .main-btn{
        display: none;
    }

    .product-modal{
        height: 100vh;
        width: 100vw;
        overflow: hidden;

        .modal-content{
            margin-top: 40px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            height: calc(100vh - 40px);

            .product-counter{
                position: fixed;
                z-index: 1;
                bottom: 0;
                left: 0;
                width: 100vw;
                padding:10px;
                background: #fff;
            }
        }

        .modal-dialog{
            padding: 0;
            margin: 0;
        }
        .modal-body{
            overflow-y: auto;
            //height: calc(100vh - 40px);
        }
        .product-modal-content{
            margin-bottom: 20px;
        }
    }
    .address-modal{
        .modal-dialog{
            padding: 0;
            margin: 0;
        }
        .modal-content{
            height: 100vh;
            border-radius: 0;
            overflow-x: hidden;
            overflow-y: auto;
        }
        .close{
            top: 10px;
            right: 10px;
            color: #222831;
            z-index: 10000;
            background: #fff;
            display: flex;
        }
    }
    .address-modal-wrapper{
        grid-template-columns: 1fr;
        .map-container{
            grid-row: 1;
            height: 350px;

            .leaflet-container{
                height: 350px;
                .leaflet-control input[type="text"]{
                    width: 200px;
                }
                border-radius: 0;
            }
            .address-pin{
                top: auto;
                bottom: 20px;
            }
        }
        .address-modal-container{
            position: relative;
            z-index: 20000;

            .mob-none{
                display: none;
            }
        }
        
    }
    .product-modal-wrapper .product-recomended{
        max-width: 122px;
        .price{
            font-size: 13px;
        }
        .title{
            font-size: 12px;
        }
    }
}

@media(max-width: 556px){
    .product-modal-content{
        padding: 16px 0;
    }
    /*.stories-modal .modal-dialog{
        max-width: 100%;
        padding: 0 24px;
        .slick-next{
            right: -15px;
        }
        .slick-prev{
            left: -35px;
        }
    }
    .stories-modal .slick-prev:before, .stories-modal .slick-next:before{
        font-size: 40px;
    }*/
    
}
@media(max-width: 480px){
    .address-modal{
        padding: 0 !important;
    }
    .address-modal-container{
        padding: 20px;
    }
    .profile-dashboard{
        .span{
            font-size: 0;
            i{
                font-size: 20px;
            }
        }
    }
    .to-wishlist{
        color: rgba(207, 207, 207, 0.6);
    }
    .mb-50{
        margin-bottom: 24px;
    }
    .container{
        padding: 0 16px;
    }
    .stories-slider .slick-track{
        gap: 0;
    }
    .stories-slider .slick-slide{
        margin-right: 10px;
    }
    .stories-slider .slick-list{
        padding: 16px 0;
    }
    .grid{
        grid-template-columns: 1fr;
    }
    .stories-item img{
        height: 124px;
    }
    .product-card{
        display: grid;
        grid-template-columns: 113px 1fr;
        gap: 10px;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.05);
        //padding: 10px;
        text-align: start;
        .product-flex{
            padding: 10px;
        }
        img{
            height: 100%;
            min-height: 124px;
            width: 100%;
            margin: 0;
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
        }
        h4{
            font-size: 16px;
            display: grid;
            line-height: 1;
            margin: 0 20px 4px 0;
            .price{
                font-weight: 700;
                color: var(--accentColor);
                grid-row: 3;
            }
        }
        .product-card-wrapper p{
            margin-bottom: 8px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
        }
        .to-wishlist{
            width: 20px;
            height: 20px;
            font-size: 20px;
        }
        .card-relative{
            position: initial;
            .modal-states{
                flex-direction: column;
                font-size: 12px;
                gap: 4px;
                bottom: 9px;
                left: 9px;

                .product-state, .product-bonus{
                    padding: 0 8px;
                }
            }
        }
    }
}
@media(max-width:410px){
    .product-modal-wrapper .product-recomended{
        max-width: 110px;
    }
}
@media(max-width: 380px){
    .product-card{
        grid-template-columns: 100px 1fr;
    }
    .product-card img{
        min-height: 110px;
    }
}
