.checkout{
    margin: 50px 0;
    h1{
        margin-bottom: 30px;
    }
}
.box{
    border-radius: 16px;
    background: #FAFAFA;
    padding: 24px;
    margin-bottom: 20px;

    h4{
        margin-bottom: 20px;

        span{
            color: red;
        }
    }
}
.bottom-checkount{
    color: #222831;
    font-size: 16px;
    &:not(:last-child){
        margin-bottom: 12px;
    }
    display: flex;
    align-items: center;
    i{
        font-size: 20px;
        color: var(--mainColor);
        margin-right: 8px;
    }
    span{
        font-weight: 700;
        margin-left: 8px;
    }
}
.form-check-label{
    cursor: pointer;
}
.form-check-input{
    margin: 0 8px 0;
}
.form-check-input:checked{
    background-color: var(--mainColor);
    border-color: var(--mainColor);
}
.form-check-input:focus{
    box-shadow: none;
}
.delivery-items{
    display: flex;
    gap: 20px;
    .accordion-item{
        width: 100%;
        border: none;
        border-radius: 21px;
    }
    .accordion-header{
        border-radius: 21px;
    }
    .after-none{
        .accordion-button::after{
            display: none !important;
        }
    }
    .accordion-button:focus{
        box-shadow: none;
        border: none;
    }
    .accordion-item .accordion-button{
        border-radius: 21px;
    }
    .accordion-button:not(.collapsed){
        box-shadow: none;
        background: none;
    }
    .accordion-item:last-of-type .accordion-button.collapsed{
        border-radius: 21px;
    }
}
.payments{
    flex-direction: column;
}
.form-label{
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    label{
        margin-bottom: 4px;
    }
}
.form-label-radio{
    margin-top: 8px;
}
.checkout-product-card{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E1E1E1;
    padding-bottom: 16px;
    margin-bottom: 16px;
    
    h6{
        margin-bottom: 0;
        font-size: 16px;
    }

    .success{
        color: #9CA5AF;
    }
}
.summary-wraper{
    padding: 12px 0 0;
}
.checkout-bottom{
    margin-top: 12px;
    border-top: 1px solid #E1E1E1;
    padding-top: 12px;
    .main-btn{
        margin-top: 12px;
        width: 100%;
    }
}
.delivery-point-card2{
    border-radius: 8px;
    border: 1px solid var(--mainColor);
    background: #FFF;
    padding: 12px;
    color: #5D6167;
    font-size: 16px;
    font-weight: 500;

    span{
        color: #9CA5AF;
        font-size: 14px;
        margin-top: 8px;
    }
}
.promocode-text{
    color: #7B7B7B;
    font-size: 12px;
    margin-top: 9px;

    &.red{
        color: #FF3000;
    }
}