.app-banner{
    display: flex;
    padding: 80px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 32px;
    background-image: url('../../images/banner_back3.png');

    .app-baner-content{
        max-width: 480px;
        h2{
            font-size: 40px;
            font-weight: 400;
        }
        .description-flex{
            margin-top: 50px;
        }
        .subtitle{
            color: var(--mainColor);
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 2px;
            text-transform: uppercase;
            margin-bottom: 24px;
        }
    }
    .mob-image{
        width: 280px;
    }
}

@media(max-width: 996px){
    .app-banner{
        padding: 37px 0 0 53px;

        .app-baner-content{
            padding: 43px 20px 0 40px;

            .subtitle{
                font-size: 14px;
            }
            h2{
                font-size: 28px;
            }
        }

        .mob-image{
            width: 200px;
        }
    }
}

@media(max-width: 776px){
    .app-banner{
        padding: 52px 34px 0;
        display: grid;
        grid-template-columns: 1fr;

        .mob-image{
            grid-row: 2;
        }
        .app-baner-content{
            padding: 0;
            margin-bottom: 24px;

            .subtitle{
                font-size: 12px;
            }
            h2{
                font-size: 24px;
            }
        }
    }
}