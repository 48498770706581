.present-stock{
    .delivery-stock-title i{
        color:#F49627;
    }
    .delivery-stock-title .output{
        background:#F49627;
    }
    &.present-stock-full{
        .delivery-stock-title i{
            color: #fff;
            font-size: 30px;
            transition: .3s;
        }
        .delivery-stock-title .inner{
            background: #F49627;
            width: 56px;
            height: 56px;
            position: relative;

            &::after{
                position: absolute;
                content: "";
                width: 66px;
                height: 66px;
                background: #f4962747;
                border-radius: 50%;
                z-index: -1;
                opacity: 0;
            }

            &::before{
                position: absolute;
                content: "";
                width: 80px;
                height: 80px;
                background: #f4962747;
                border-radius: 50%;
                z-index: -1;
                opacity: 0;
                animation: presentAnimation .7s linear;
            }
        }
    }
}

@keyframes presentAnimation{
    0%{
        opacity: 1;
        transform: scale(1.1);
    }
    100%{
        transform: scale(1.2);
        opacity: 0;
    }
}

@keyframes scaleAnimation{
    0%{
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}