.notification-top{
    background: var(--notificationColor);
    color: var(--notificationFontColor);
    padding: 5px;
    .notification-content{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
    }
}