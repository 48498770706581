@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-ExtraLight.ttf");
    src: url("../fonts/Nunito/Nunito-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-ExtraLightItalic.ttf");
    src: url("../fonts/Nunito/Nunito-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-Light.ttf");
    src: url("../fonts/Nunito/Nunito-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-LightItalic.ttf");
    src: url("../fonts/Nunito/Nunito-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-Regular.ttf");
    src: url("../fonts/Nunito/Nunito-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-Medium.ttf");
    src: url("../fonts/Nunito/Nunito-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-MediumItalic.ttf");
    src: url("../fonts/Nunito/Nunito-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-SemiBold.ttf");
    src: url("../fonts/Nunito/Nunito-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-SemiBoldItalic.ttf");
    src: url("../fonts/Nunito/Nunito-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-Bold.ttf");
    src: url("../fonts/Nunito/Nunito-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-BoldItalic.ttf");
    src: url("../fonts/Nunito/Nunito-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-ExtraBold.ttf");
    src: url("../fonts/Nunito/Nunito-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-ExtraBoldItalic.ttf");
    src: url("../fonts/Nunito/Nunito-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-ExtraBoldItalic.ttf");
    src: url("../fonts/Nunito/Nunito-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-Black.ttf");
    src: url("../fonts/Nunito/Nunito-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: "Nunito";
    src: url("../fonts/Nunito/Nunito-BlackItalic.ttf");
    src: url("../fonts/Nunito/Nunito-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}