// top-bar
.logo{
    object-fit: contain;
    width: 115px;
    height: 59px;
}
.flex{
    display: flex;
    justify-content: space-between;
}

.top-bar{
    border-top: 1px solid #DDD;
    border-bottom: 1px solid #DDD;
    background: #FFF;
    padding: 23px 0;
}
.top-bar-address{
    margin-left: 46px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    b{
        color: var(--mainColor);
        font-weight: 600;
        cursor: pointer;
    }
    .location{
        color: var(--mainColor);
        display: flex;
        align-items: center;
        cursor: pointer;
        
        p{
            text-decoration: underline;
        }

        i{
            margin-right: 4px;
            text-decoration: none;
        }
    }
}
.menu-burger{
    display: none;
    cursor: pointer;
    color: #30475E;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;

    &.open{
        i:before{
            font-size: 20px;
            content: "\e90a";
        }
    }
}
.mob-user{
    display: none;
}
.back-close{
    width: 100%;
    height: 100vh;
    background-color: #0000008c;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
}
.close-button{
    display: none;
    margin: 10px 10px 0;
    cursor: pointer;
}
.top-text{
    color: #9CA5AF;
    font-size: 11px;
    text-align: end;
    a{
        color: #222831;
        font-size: 16px;
        font-weight: 600;
    }
}
.top-bar-wrapper{
    display: flex;
    gap: 16px;
    align-items: center;
}

//top-navigation
.top-navigation{
    background: #FFF;
}
.top-nav{
    display: flex;
    gap: 24px;
    align-items: center;
    padding: 16px 0;
    a{
        color: #9CA5AF;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.2px;
        transition: .3s;
        
        &:hover{
            color: var(--mainColor);
        }
    }
}
//menu
.menu{
    border-bottom: 1px solid #DDD;
    background: #FFF;
    padding: 15px 0;

    .main-btn{
        margin: 5px 0;
    }
}
.nav{
    display: flex;
    gap: 32px;
    align-items: center;
    position: relative;
    overflow-x: hidden;
    flex-wrap: nowrap;
    padding: 5px 0;
    margin-right: 30px;
    a, .nav-item{
        cursor: pointer;
        color: #30475E;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.2px;
        transition: .3s;
        white-space: nowrap;

        &:hover{
            color: var(--mainColor);
        }
        &.active{
            color: var(--mainColor);
        }
    }
    &::-webkit-scrollbar {
        height: 5px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
    }
    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;
        border: 1px solid #f1f1f1;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #000000;
    }
    &:hover{
        overflow-x: auto;
    }
}

.logo-sticky{
    transform: translate(-200px);
    position: absolute;
    transition: .3s;
    img{
        min-width: 115px;
        max-height: 40px;
        object-fit: contain;
    }
}
.menu.sticky{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    padding: 10px 0 5px;

    .logo-sticky{
        transform: translate(0);
        position: relative;
    }
}
.user-icon{
    position: relative;
    display: flex;
    align-items: center;
    color: #30475E;
    font-weight: 500;
    cursor: pointer;

    .user-icon-title{
        border-radius: 31px;
        border: 1px solid #F2F2F2;
        padding: 8px 29px 8px 15px;
        color: #263238;
        text-align: center;
        font-size: 16px;
        position: relative;

        &::after{
            position: absolute;
            top: 14px;
            right: 15px;
            content: "\e92c";
            font-family: 'icomoon' !important;
            font-size: 10px;
            color: #9CA5AF;
        }

        i{
            font-size: 18px;
            margin-right: 4px;
            color: var(--mainColor);
        }
    }

    .user-icon-content{
        position: absolute;
        right: 0;
        top: 53px;
        border-radius: 16px;
        border: 1px solid #F2F2F2;
        background: #FFF;
        width: 200px;
        padding: 8px;
        z-index: 3;
        opacity: 0;
        visibility: hidden;

        &.active{
            opacity: 1;
            visibility: visible;
        }
    }
    .user-icon-item{
        padding: 8px;
        color: #263238;
        border-radius: 8px;
        background: #fff;
        display: flex;
        align-items: center;
        transition: .3s;
        i{
            color: var(--mainColor);
            margin-right: 8px;
            font-size: 18px;
        }

        &:hover{
            background: #F9F9F9;
        }

        :not(:last-child){
            margin-bottom: 4px;
        }
    }
}
.user-bonuses{
    color: #fff;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 30px;
    background: var(--bonusColor);
    display: flex;
    align-items: center;
    cursor: pointer;

    i{
        margin-right: 4px;
        font-size: 19px;
    }
}

.lang-city-select{
    color: #30475E;
    font-size: 14px;
    font-weight: 500;
    justify-self: end;
    align-items: center;
    cursor: pointer;
    display: none;

    i{
        font-size: 24px;
        position: relative;
        transition: transform .3s;
        margin-left: 8px;
        &::after{
            content: attr(data-title);
            position: absolute;
            top: -7px;
            background: var(--accentColor);
            color: #fff;
            padding: 0 4px;
            border-radius: 32px;
            font-size: 12px;
            border: 1px solid #FFF;
            right: -4px;
            line-height: initial;
            display: flex;
            align-items: center;
        }
    }
}


@media(max-width: 767px){
    .lang-city-select{
        display: flex;
    }
}
.bonus-modal{
    .profile-bonus{
        padding: 16px;
        .bonus{
            font-size: 24px;

            i{
                font-size: 20px;
            }
        }
        .profile-bonus-middle{
            margin-top: 0;
        }
        .profile-info{
            display: none;
        }
    }
}