@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-Black.ttf");
    src: url("../fonts/Raleway/Raleway-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-BlackItalic.ttf");
    src: url("../fonts/Raleway/Raleway-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-Bold.ttf");
    src: url("../fonts/Raleway/Raleway-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-BoldItalic.ttf");
    src: url("../fonts/Raleway/Raleway-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-ExtraBold.ttf");
    src: url("../fonts/Raleway/Raleway-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-ExtraBoldItalic.ttf");
    src: url("../fonts/Raleway/Raleway-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-ExtraLight.ttf");
    src: url("../fonts/Raleway/Raleway-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-ExtraLightItalic.ttf");
    src: url("../fonts/Raleway/Raleway-ExtraLightItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-Italic.ttf");
    src: url("../fonts/Raleway/Raleway-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-Light.ttf");
    src: url("../fonts/Raleway/Raleway-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-LightItalic.ttf");
    src: url("../fonts/Raleway/Raleway-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-Medium.ttf");
    src: url("../fonts/Raleway/Raleway-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-MediumItalic.ttf");
    src: url("../fonts/Raleway/Raleway-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-Regular.ttf");
    src: url("../fonts/Raleway/Raleway-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-SemiBold.ttf");
    src: url("../fonts/Raleway/Raleway-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-SemiBoldItalic.ttf");
    src: url("../fonts/Raleway/Raleway-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-Thin.ttf");
    src: url("../fonts/Raleway/Raleway-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-ThinItalic.ttf");
    src: url("../fonts/Raleway/Raleway-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}
